import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_vc_editor = _resolveComponent("vc-editor")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    title: "Compose Emails",
    width: "40%",
    onClosed: _cache[9] || (_cache[9] = ($event: any) => (_ctx.close(false))),
    top: "1vh",
    class: "compose-email1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: _ctx.email,
        "status-icon": "",
        rules: _ctx.rule.emails,
        "scroll-to-error": "",
        id: "emails",
        onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.submitTask(_ctx.ruleFormRef)), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 10,
            class: "emails-details"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_input, {
                    label: "From",
                    prop: "from",
                    size: "large",
                    "is-required": "",
                    modelValue: _ctx.email.from,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email.from) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_input, {
                    label: "To",
                    prop: "to",
                    size: "large",
                    "is-required": "",
                    modelValue: _ctx.email.to,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email.to) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_input, {
                    label: "Subject",
                    prop: "subject",
                    size: "large",
                    "is-required": "",
                    modelValue: _ctx.email.subject,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email.subject) = $event)),
                    ref: "subRef"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "body" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_vc_editor, {
                        style: {"height":"200px !important"},
                        initialValue: "<p>Initial editor content</p>",
                        apiKey: "tsuzz6ne0rmy4zerpuuabnc0qcbptajcv2yg2qg47xy2rtay",
                        modelValue: _ctx.email.body,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email.body) = $event)),
                        init: {
                                    height: 100,
                                    menubar: false,
                                    plugins: [
                                    'advlist autolink lists link image charmap',
                                    'searchreplace visualblocks code fullscreen',
                                    'print preview anchor insertdatetime media',
                                    'paste code help wordcount table',
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic |         alignleft aligncenter alignright |         bullist numlist outdent indent | help',
                                },
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validate(_ctx.ruleFormRef))),
                        onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validate(_ctx.ruleFormRef)))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 24,
                class: "task-details-submit"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_vc_button, {
                      className: "btn btn-lg btn-light me-4",
                      text: "Cancel",
                      size: "large",
                      onVcClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.close(false))),
                      "is-regular": ""
                    }),
                    _createVNode(_component_vc_button, {
                      loading: _ctx.loading,
                      className: "btn btn-lg btn-info",
                      text: "Save",
                      size: "large"
                    }, null, 8, ["loading"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}