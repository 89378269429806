import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_task_form = _resolveComponent("vc-customer-task-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    title: "Close Task",
    width: "25%",
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close(false))),
    class: "close-task-modal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_vc_customer_task_form, {
        customer_id: _ctx.customer.customer_id,
        agent: _ctx.customer.agent,
        lead_id: _ctx.customer.lead_id,
        lead_status_id: _ctx.customer.lead_status_id,
        "disable-button": _ctx.customer.disable_task_button,
        "is-modal": "",
        onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(false))),
        ref: "task_form"
      }, null, 8, ["customer_id", "agent", "lead_id", "lead_status_id", "disable-button"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}