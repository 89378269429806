import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 4,
  class: "d-flex"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.invoice.categories,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (group, i) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: i,
            width: group.width,
            "header-align": "top"
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", {
                class: "d-block fs-6 fw-bolder",
                innerHTML: group.label
              }, null, 8, _hoisted_2)
            ]),
            default: _withCtx((scope) => [
              (group.value == 'group_count')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    prop: `categories.` + scope.$index +`.group_count`,
                    rules: {
                                    validator: (rule, value, callback) => {
                                        _ctx.Validations.group_count(rule, value, callback, scope.row.amount)
                                    }, trigger: ['blur', 'change']
                                }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        type: 'number',
                        ref_for: true,
                        ref: `categories.` + scope.$index + `.group_count`,
                        onFocus: ($event: any) => (_ctx.selectField(`categories.` + scope.$index + `.group_count`)),
                        placeholder: "Members Count",
                        min: "0",
                        size: "large",
                        style: {"width":"100%"},
                        modelValue: scope.row['group_count'],
                        "onUpdate:modelValue": ($event: any) => ((scope.row['group_count']) = $event),
                        onChange: ($event: any) => (_ctx.updateValue(scope.row, 'group_count', scope.$index))
                      }, null, 8, ["onFocus", "modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]))
                : (group.value == 'members_above_60')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      prop: `categories.` + scope.$index +`.members_above_60`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: 'number',
                          ref_for: true,
                          ref: `categories.` + scope.$index + `.members_above_60`,
                          onFocus: ($event: any) => (_ctx.selectField(`categories.` + scope.$index + `.members_above_60`)),
                          placeholder: "Members > 60",
                          min: "0",
                          size: "large",
                          style: {"width":"100%"},
                          modelValue: scope.row['members_above_60'],
                          "onUpdate:modelValue": ($event: any) => ((scope.row['members_above_60']) = $event),
                          onChange: ($event: any) => (_ctx.updateValue(scope.row, 'members_above_60'))
                        }, null, 8, ["onFocus", "modelValue", "onUpdate:modelValue", "onChange"])
                      ]),
                      _: 2
                    }, 1032, ["prop"]))
                  : (group.value == 'basmah')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        prop: `categories.` + scope.$index +`.basmah`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_vc_amount_v2, {
                            "with-prop": false,
                            type: 'number',
                            ref_for: true,
                            ref: `categories.` + scope.$index + `.basmah`,
                            placeholder: "Basmah",
                            min: "1",
                            size: "large",
                            style: {"width":"100%"},
                            modelValue: scope.row['basmah'],
                            "onUpdate:modelValue": ($event: any) => ((scope.row['basmah']) = $event),
                            disabled: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop"]))
                    : (group.value == 'icp')
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 3,
                          prop: `categories.` + scope.$index +`.icp`,
                          rules: { validator: _ctx.Validations.icp, trigger: ['blur', 'change','input'] }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_vc_amount_v2, {
                              "with-prop": false,
                              ref_for: true,
                              ref: `categories.` + scope.$index + `.icp`,
                              placeholder: "ICP",
                              min: "0",
                              size: "large",
                              style: {"width":"100%"},
                              modelValue: scope.row['icp'],
                              "onUpdate:modelValue": ($event: any) => ((scope.row['icp']) = $event),
                              disabled: scope.row['icp_disabled'] || _ctx.allDisabled,
                              onPress: _ctx.updateIcp
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onPress"])
                          ]),
                          _: 2
                        }, 1032, ["prop", "rules"]))
                      : (group.value == 'amount')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_el_form_item, {
                              prop: `categories.` + scope.$index +`.amount`,
                              rules: {
                                    validator: (rule, value, callback) => {
                                        _ctx.Validations.amendAmount(rule, value, callback, scope.row.group_count)
                                    }, trigger: ['blur', 'change']
                                }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_vc_amount_v2, {
                                  "with-prop": false,
                                  ref_for: true,
                                  ref: `categories.` + scope.$index + `.amount`,
                                  placeholder: "amount",
                                  size: "large",
                                  style: {"width":"100%"},
                                  modelValue: scope.row['amount'],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row['amount']) = $event),
                                  onPress: ($event: any) => (_ctx.updatePolicyPrice(scope.row)),
                                  disabled: _ctx.allDisabled
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onPress", "disabled"])
                              ]),
                              _: 2
                            }, 1032, ["prop", "rules"]),
                            _createVNode(_component_vc_amount_v2, {
                              "with-prop": false,
                              disabled: "",
                              label: 'VAT',
                              modelValue: scope.row['amount_vat'],
                              "onUpdate:modelValue": ($event: any) => ((scope.row['amount_vat']) = $event),
                              style: {"width":"33%"},
                              placeholder: "VAT",
                              size: "large",
                              isRequired: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]))
                        : (group.value == 'non_taxable_fee')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 5,
                              prop: `categories.` + scope.$index +`.non_taxable_fee`,
                              rules: { validator: _ctx.Validations.non_taxable_fee, trigger: ['blur', 'change','input'] }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_vc_amount_v2, {
                                  "with-prop": false,
                                  ref_for: true,
                                  ref: `categories.` + scope.$index + `.non_taxable_fee`,
                                  placeholder: "Non Taxable Fee",
                                  min: "1",
                                  size: "large",
                                  style: {"width":"100%"},
                                  modelValue: scope.row['non_taxable_fee'],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row['non_taxable_fee']) = $event),
                                  onPress: _ctx.updateNonTaxableFee,
                                  disabled: _ctx.allDisabled
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onPress", "disabled"])
                              ]),
                              _: 2
                            }, 1032, ["prop", "rules"]))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 6,
                              innerHTML: scope.row[group.value]
                            }, null, 8, _hoisted_4))
            ]),
            _: 2
          }, 1032, ["width"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}