import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1"
}
const _hoisted_2 = {
  key: 0,
  class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isRegular)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: _ctx.color,
                class: _normalizeClass(_ctx.className),
                "native-type": _ctx.type,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('vcClick'))),
                size: _ctx.size
              }, {
                default: _withCtx(() => [
                  (_ctx.isIcon)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        _createElementVNode("i", {
                          class: _normalizeClass(_ctx.icon)
                        }, null, 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
                ]),
                _: 1
              }, 8, ["type", "class", "native-type", "size"]))
            : (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: _ctx.color,
                loading: "",
                size: _ctx.size
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Please wait... ")
                ]),
                _: 1
              }, 8, ["type", "size"]))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.isRegular)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass(_ctx.className),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('vcClick'))),
          type: "button"
        }, [
          (_ctx.isIcon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                (!_ctx.isIconLocal)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(_ctx.icon)
                    }, null, 2))
                  : _createCommentVNode("", true),
                (_ctx.isIconLocal)
                  ? (_openBlock(), _createBlock(_component_inline_svg, {
                      key: 1,
                      src: _ctx.icon
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}