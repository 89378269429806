import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-content-between pagination-row"
}
const _hoisted_2 = { class: "col-md-4" }
const _hoisted_3 = { class: "col-md-4 text-center" }
const _hoisted_4 = { class: "text-muted d-block mt-2" }
const _hoisted_5 = { class: "col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_ctx.allNotifications)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.listingPayload.per_page,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listingPayload.per_page) = $event)),
            size: "large",
            onChange: _ctx.selectedPage,
            style: {"width":"20%"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item,
                  value: item
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, " Showing " + _toDisplayString(_ctx.allNotifications.from) + " to " + _toDisplayString(_ctx.allNotifications.to) + " of " + _toDisplayString(_ctx.allNotifications.total), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_pagination, {
            style: {"justify-content":"flex-end"},
            currentPage: _ctx.listingPayload.index,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listingPayload.index) = $event)),
            "page-size": _ctx.listingPayload.per_page,
            "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.listingPayload.per_page) = $event)),
            small: false,
            background: false,
            layout: "prev, pager, next",
            total: _ctx.allNotifications.total,
            onSizeChange: _ctx.getLists,
            onCurrentChange: _ctx.getLists
          }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}