import { store } from "@/store";
import { App } from "vue";
import { ReportActionTypes } from '@/store/enums/Report/Actions';
import { ReportMutationTypes } from '@/store/enums/Report/Mutations';

class Report {
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Report.vueInstance = app;
    }

    public static async getReportDashboardDetails(payload) {
        return await store.dispatch(ReportActionTypes.GET_REPORT_DASHBOARD_DETAILS, payload)
    }

    public static async getReportDeletedLeads(payload) {
        return await store.dispatch(ReportActionTypes.GET_REPORT_DELETED_LEADS, payload)
    }

    public static async getReportLeadBySourceFilter(payload) {
        return await store.dispatch(ReportActionTypes.GET_REPORT_LEAD_BY_SOURCE_FILTER, payload)
    }

    public static async getReportLeadSourceFilter(payload) {
        return await store.dispatch(ReportActionTypes.GET_REPORT_LEAD_SOURCE_FILTER, payload)
    }

    public static async getRequestedURL(payload) {
        return await store.dispatch(ReportActionTypes.GET_REQUESTED_URL, payload)
    }

    public static async getAgentSalesReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_AGENT_SALES_REPORT, payload)
    }

    public static async getPoliciesAssignedVsCompletedReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT, payload)
    }

    public static async getAdminReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_ADMIN_REPORT, payload)
    }

    public static async getApprovedInvoicesReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_APPROVED_INVOICES_REPORT, payload)
    }

    public static async getClosedLeadsReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_CLOSED_LEADS_REPORT, payload)
    }

    public static async getConvertedLeadsReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_CONVERTED_LEADS_REPORT, payload)
    }
    public static async getLeadBySourceReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_LEAD_BY_SOURCE_REPORT, payload)
    }

    public static async generateProductionReport(payload) {
        return await store.dispatch(ReportActionTypes.GENERATE_PRODUCTION_REPORT, payload)
    }

    public static async getProductionReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_PRODUCTION_REPORT, payload)
    }


    public static async getAccountMasterReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_ACCOUNT_MASTER_REPORT, payload)
    }

    public static async spendCampaignFilters(payload) {
        return await store.dispatch(ReportActionTypes.GET_MARKETING_CAMPAIGN_FILTERS, payload)
    }
    
    public static async getCampaignAmountSpend(payload) {
        return await store.dispatch(ReportActionTypes.GET_MARKETING_CAMPAIGN_AMOUNT_SPEND, payload)
    }

    
    public static async addUpdateMarketingSpend(payload) {
        return await store.dispatch(ReportActionTypes.UPDATE_MARKETING_CAMPAIGN_AMOUNT_SPEND, payload)
    }

    public static setCampaignAmountSpend(payload) {
        store.commit(ReportMutationTypes.SET_MARKETING_CAMPAIGN_AMOUNT_SPEND, payload);
    }

    
    public static async getMarketingReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_MARKETING_REPORT, payload)
    }

    public static async getAgentLeadActivityReport(payload) {
        return await store.dispatch(ReportActionTypes.GET_AGENT_LEAD_ACTIVITY_REPORT, payload)
    }

}


export default Report