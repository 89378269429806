import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vc_amount_v2 = _resolveComponent("vc-amount-v2")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.invoice.categories,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sub_amend_group_columns, (group, i) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: i,
            width: group.width,
            "header-align": "top"
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", {
                class: "d-block fs-6 fw-bolder",
                innerHTML: group.label
              }, null, 8, _hoisted_2)
            ]),
            default: _withCtx((scope) => [
              (group.value == 'group_count')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    prop: `categories.` + scope.$index +`.group_count`,
                    rules: {
                                        validator: (rule, value, callback) => {
                                            _ctx.Validations.sub_group_count(rule, value, callback, scope.row.all_group_counts, scope.row['amount']); // Pass 18 as a custom parameter
                                        }, trigger: ['blur', 'change']
                            }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        type: 'number',
                        ref_for: true,
                        ref: `categories.` + scope.$index + `.group_count`,
                        placeholder: "Members Count",
                        min: "0",
                        size: "large",
                        style: {"width":"100%"},
                        modelValue: scope.row['group_count'],
                        "onUpdate:modelValue": ($event: any) => ((scope.row['group_count']) = $event),
                        onChange: ($event: any) => (_ctx.updateValue(scope.row, 'group_count', scope.$index))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]))
                : (group.value == 'members_above_60')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      prop: `categories.` + scope.$index +`.members_above_60`,
                      rules: {
                                        validator: (rule, value, callback) => {
                                            _ctx.Validations.sub_members_greater_60(rule, value, callback, scope.row.all_members_above_60s); // Pass 18 as a custom parameter
                                        }, trigger: ['blur', 'change']
                            }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: 'number',
                          ref_for: true,
                          ref: `categories.` + scope.$index + `.members_above_60`,
                          placeholder: "Members > 60",
                          min: "0",
                          size: "large",
                          style: {"width":"100%"},
                          modelValue: scope.row['members_above_60'],
                          "onUpdate:modelValue": ($event: any) => ((scope.row['members_above_60']) = $event),
                          onChange: ($event: any) => (_ctx.updateValue(scope.row, 'members_above_60'))
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"]))
                  : (group.value == 'basmah')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        prop: `categories.` + scope.$index +`.basmah`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            type: 'number',
                            ref_for: true,
                            ref: `categories.` + scope.$index + `.basmah`,
                            placeholder: "Basmah",
                            min: "1",
                            size: "large",
                            style: {"width":"100%"},
                            modelValue: scope.row['basmah'],
                            "onUpdate:modelValue": ($event: any) => ((scope.row['basmah']) = $event),
                            disabled: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop"]))
                    : (group.value == 'icp')
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 3,
                          prop: `categories.` + scope.$index +`.icp`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: 'number',
                              ref_for: true,
                              ref: `categories.` + scope.$index + `.icp`,
                              placeholder: "ICP",
                              min: "0",
                              size: "large",
                              style: {"width":"100%"},
                              modelValue: scope.row['icp'],
                              "onUpdate:modelValue": ($event: any) => ((scope.row['icp']) = $event),
                              disabled: scope.row['icp_disabled'] || _ctx.allDisabled,
                              onInput: _ctx.updateIcp,
                              onKeypress: _cache[0] || (_cache[0] = ($event: any) => (_ctx.common.isNumber($event)))
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onInput"])
                          ]),
                          _: 2
                        }, 1032, ["prop"]))
                      : (group.value == 'amount')
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 4,
                            prop: `categories.` + scope.$index +`.amount`,
                            rules: {
                                        validator: (rule, value, callback) => {
                                            _ctx.Validations.sub_group_refund_amount(rule, value, callback, scope.row['group_count']);
                                        }, trigger: ['blur', 'change']
                            }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_vc_amount_v2, {
                                "with-prop": false,
                                ref_for: true,
                                ref: `categories.` + scope.$index + `.amount`,
                                placeholder: "amount",
                                min: "1",
                                size: "large",
                                style: {"width":"100%"},
                                modelValue: scope.row['amount'],
                                "onUpdate:modelValue": ($event: any) => ((scope.row['amount']) = $event),
                                onInput: _ctx.updatePolicyPrice,
                                disabled: _ctx.allDisabled
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "disabled"])
                            ]),
                            _: 2
                          }, 1032, ["prop", "rules"]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 5,
                            innerHTML: scope.row[group.value]
                          }, null, 8, _hoisted_3))
            ]),
            _: 2
          }, 1032, ["width"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}