// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum AuthMutationTypes {
    SET_AUTH_ERROR = 'SET_AUTH_ERROR',
    SET_AUTH = 'SET_AUTH',
    SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
    SET_AUTH_USER = 'SET_AUTH_USER',
    SET_AUTH_LOADING = 'SET_AUTH_LOADING',
    CLEAR_AUTH = 'CLEAR_AUTH',
    SET_OPEN_PASSWORD_RESET = 'SET_OPEN_PASSWORD_RESET',
    SET_PASSWORD_RESET = 'SET_PASSWORD_RESET',
    SET_PASSWORD_RESET_POPULATED = 'SET_PASSWORD_RESET_POPULATED'
  }
  