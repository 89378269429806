import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vc"
}
const _hoisted_2 = { class: "custom-date-picker" }
const _hoisted_3 = {
  key: 1,
  class: "vc"
}
const _hoisted_4 = { class: "custom-date-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_calendar = _resolveComponent("calendar")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_ctx.withProp)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_form_item, {
          prop: _ctx.prop,
          rules: _ctx.rules,
          class: "el-form-item-custom-date-picker"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_input, {
                type: "text",
                placeholder: "DD/MM/YYYY",
                modelValue: _ctx.firstDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstDate) = $event)),
                onInput: _ctx.updateValue,
                onChange: _ctx.changeValue,
                maxlength: "10",
                disabled: _ctx.disabled,
                ref: "inputRef",
                size: "large",
                onClick: _ctx.openCalendar,
                onKeydown: _withKeys(_ctx.closeCalendar, ["enter"])
              }, {
                suffix: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    style: {"visibility":"hidden","width":"1px"},
                    modelValue: _ctx.firstDate,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstDate) = $event)),
                    type: "date",
                    placeholder: "Pick a Date",
                    format: "YYYY/MM/DD",
                    "value-format": "DD/MM/YYYY",
                    "disabled-date": _ctx.disabledDate,
                    ref: "calRef",
                    "default-value": _ctx.defaultValue,
                    onChange: _ctx.changeValue
                  }, null, 8, ["modelValue", "disabled-date", "default-value", "onChange"]),
                  _createVNode(_component_el_icon, {
                    class: "el-input__icon pointer",
                    onClick: _ctx.openCalendar
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_calendar)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["modelValue", "onInput", "onChange", "disabled", "onClick", "onKeydown"])
            ])
          ]),
          _: 1
        }, 8, ["prop", "rules"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_input, {
            type: "text",
            placeholder: "DD/MM/YYYY",
            modelValue: _ctx.firstDate,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.firstDate) = $event)),
            onInput: _ctx.updateValue,
            onChange: _ctx.changeValue,
            maxlength: "10",
            disabled: _ctx.disabled,
            ref: "inputRef",
            size: "large",
            onClick: _ctx.openCalendar,
            onKeydown: _withKeys(_ctx.closeCalendar, ["enter"])
          }, {
            suffix: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                style: {"visibility":"hidden","width":"1px"},
                modelValue: _ctx.firstDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstDate) = $event)),
                type: "date",
                placeholder: "Pick a Date",
                format: "YYYY/MM/DD",
                "value-format": "DD/MM/YYYY",
                "disabled-date": _ctx.disabledDate,
                "default-value": _ctx.defaultValue,
                ref: "calRef",
                onChange: _ctx.changeValue
              }, null, 8, ["modelValue", "disabled-date", "default-value", "onChange"]),
              _createVNode(_component_el_icon, {
                class: "el-input__icon pointer",
                onClick: _ctx.openCalendar
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_calendar)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["modelValue", "onInput", "onChange", "disabled", "onClick", "onKeydown"])
        ])
      ]))
}