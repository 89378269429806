import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-5" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.actionData.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.actionData.show) = $event)),
    "show-close": false,
    "close-on-click-modal": false,
    width: "500px",
    title: _ctx.actionData.status == '2' ? 'Approve' : (_ctx.actionData.status == '3' ? 'Decline' : '')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _createTextVNode(" Are you sure you want " + _toDisplayString(_ctx.actionData.status == "2" ? "Approve" : (_ctx.actionData.status == "3" ? "Decline" : '')) + " ", 1),
          _createElementVNode("b", null, _toDisplayString(_ctx.actionData.item?.merchant_reference), 1)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-light me-5",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelModal')))
        }, "Cancel"),
        _createVNode(_component_vc_button_save, {
          type: "button",
          loading: _ctx.actionData.loading,
          text: 'Proceed',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('proceedModal')))
        }, null, 8, ["loading"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}