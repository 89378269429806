import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-0 fs-6 text-gray-600" }
const _hoisted_2 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.open) = $event)),
    "append-to-body": "",
    title: "Add Notes",
    width: "25%",
    onClosed: _cache[4] || (_cache[4] = ($event: any) => (_ctx.close(false)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: _ctx.notes,
        "status-icon": "",
        rules: _ctx.rule.notes,
        "scroll-to-error": "",
        id: "notes",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submitTask(_ctx.ruleFormRef)), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 10,
            class: "notes-details"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_input, {
                    label: "Notes",
                    placeholder: "Enter Notes",
                    prop: "notes",
                    size: "large",
                    isInput: "textarea",
                    "is-required": "",
                    rows: 4,
                    maxlength: 255,
                    modelValue: _ctx.notes.notes,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notes.notes) = $event)),
                    class: _normalizeClass('mb-0')
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("span", _hoisted_1, " Up to characters " + _toDisplayString(_ctx.notes?.notes?.length ?? 0) + " / 255", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 24,
                class: "task-details-submit pt-5"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_vc_button, {
                      className: "btn btn-lg btn-light me-4",
                      text: "Cancel",
                      size: "large",
                      onVcClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close(false))),
                      "is-regular": ""
                    }),
                    _createVNode(_component_vc_button, {
                      loading: _ctx.loading,
                      className: "btn btn-lg btn-info",
                      text: "Save",
                      size: "large"
                    }, null, 8, ["loading"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}