import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import Validations from '@/core/services/etc/Validations';
import { ReportMutationTypes } from '@/store/enums/Report/Mutations';
import { ReportActionTypes } from '@/store/enums/Report/Actions';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
}

export const actions: ActionTree<State, RootState> & Actions = {
  
  async [ReportActionTypes.GET_REPORT_DASHBOARD_DETAILS]({commit}, payload) {
      try {
        
        const data = await ApiService.post(`${Links.GET_REPORT_DASHBOARD_DETAILS}?page=${payload.page}`, payload);
        const result = data.data.data;
        
        if(!payload.export)
          commit(ReportMutationTypes.SET_REPORT_DASHBOARD_DETAILS, result)

        return result
      } catch (error) {
        return error
      }
  },
  
  async [ReportActionTypes.GET_REPORT_DELETED_LEADS]({commit}, payload) {
      try {
        
        const data = await ApiService.post(`${Links.GET_REPORT_DELETED_LEADS}?page=${payload.page}`, payload);
        const result = data.data.data;
        
        if(!payload.export)
          commit(ReportMutationTypes.SET_REPORT_DELETED_LEADS, result)

        return result
      } catch (error) {
        return error
      }
  },

  async [ReportActionTypes.GET_REPORT_LEAD_BY_SOURCE_FILTER] ({commit},payload) {
      try {
        const data = await ApiService.post(Links.GET_REPORT_LEAD_BY_SOURCE_FILTER, payload);
        const result = data.data.data.data;
        commit(ReportMutationTypes.SET_REPORT_LEAD_BY_SOURCE_FILTER, result);

        return result;
      } catch (error) {
        return error
      }
  },

  async [ReportActionTypes.GET_REPORT_LEAD_SOURCE_FILTER] ({commit},payload) {
      try {
        const data = await ApiService.post(Links.GET_REPORT_LEAD_SOURCE_FILTER, payload);
        const result = data.data.data.data;

        commit(ReportMutationTypes.SET_REPORT_LEAD_SOURCE_FILTER, result);

        return result;
      } catch (error) {
        return error
      }
  },

  async [ReportActionTypes.GET_REQUESTED_URL]({}, payload) {
    try {
      const link = getLink(payload.linkType)
      delete payload.linkType

      const data = await ApiService.post(link, payload)

      return data.data.data
    } catch (error: any) {
      return []
    }
  },
  
  async [ReportActionTypes.GET_AGENT_SALES_REPORT]({commit}, payload) {
      try {
        
        const data = await ApiService.post(Links.GET_AGENT_SALES_REPORT, payload);
        const result = data.data.data.report;
        
        // console.log('result',result)

        if(!payload.export){
          if(payload.lead_type == 1)
            commit(ReportMutationTypes.SET_AGENT_SALES_REPORT, result)
          else
            commit(ReportMutationTypes.SET_AGENT_GROUP_SALES_REPORT, result)
        }

        return data.data.data.data
      } catch (error) {
        return error
      }
  },

  async [ReportActionTypes.GET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        if(payload.lead_type == 1)
          commit(ReportMutationTypes.SET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT, result)
        else
          commit(ReportMutationTypes.SET_POLICIES_GROUP_ASSIGNED_VS_COMPLETED_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_ADMIN_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_ADMIN_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_ADMIN_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_APPROVED_INVOICES_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_APPROVED_INVOICES_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        if(payload.lead_type == 1)
          commit(ReportMutationTypes.SET_APPROVED_INVOICES_REPORT, result)
        else
          commit(ReportMutationTypes.SET_APPROVED_GROUP_INVOICES_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_CLOSED_LEADS_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_CLOSED_LEADS_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_CLOSED_LEADS_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_CONVERTED_LEADS_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_CONVERTED_LEADS_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_CONVERTED_LEADS_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_LEAD_BY_SOURCE_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_LEAD_BY_SOURCE_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_LEAD_BY_SOURCE_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GENERATE_PRODUCTION_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GENERATE_PRODUCTION_REPORT, payload);

      return data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_PRODUCTION_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_PRODUCTION_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_PRODUCTION_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_ACCOUNT_MASTER_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_ACCOUNT_MASTER_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_ACCOUNT_MASTER_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_MARKETING_CAMPAIGN_FILTERS]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_MARKETING_CAMPAIGN_FILTERS, payload);
      const result = data.data.data;
      
      // console.log('result',result)

      commit(ReportMutationTypes.SET_MARKETING_CAMPAIGN_FILTERS, result)

      return data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_MARKETING_CAMPAIGN_AMOUNT_SPEND]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_MARKETING_CAMPAIGN_AMOUNT_SPEND, payload);
      const result = data.data.data;
      
      commit(ReportMutationTypes.SET_MARKETING_CAMPAIGN_AMOUNT_SPEND, result)

      return data.data.data
    } catch (error) {
      const result = {
        total_spent:0,
        tracking: []
      }
      commit(ReportMutationTypes.SET_MARKETING_CAMPAIGN_AMOUNT_SPEND, result)

      return error
    }
  },

  async [ReportActionTypes.UPDATE_MARKETING_CAMPAIGN_AMOUNT_SPEND]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.UPDATE_MARKETING_CAMPAIGN_AMOUNT_SPEND, payload);
      
      return data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_MARKETING_REPORT]({commit}, payload) {
    try {
      
      const data = await ApiService.post(Links.GET_MARKETING_REPORT, payload);
      const result = data.data.data.report;
      
      // console.log('result',result)

      if(!payload.export){
        commit(ReportMutationTypes.SET_MARKETING_REPORT, result)
      }

      return data.data.data.data
    } catch (error) {
      return error
    }
  },

  async [ReportActionTypes.GET_AGENT_LEAD_ACTIVITY_REPORT]({commit}, payload) {
      try {
        
        const data = await ApiService.post(`${Links.GET_AGENT_LEAD_ACTIVITY_REPORT}?page=${payload.page}`, payload);
        const result = data.data.data.report;
        
        if(!payload.export)
          commit(ReportMutationTypes.SET_AGENT_LEAD_ACTIVITY_REPORT, result)

        return result
      } catch (error) {
        return error
      }
  },


};


function getLink(type) {
  let link = '';
  switch(type) {
    case 'policy-statuses': link = Links.GET_POLICY_STATUSES; break;
    case 'underwriters': link = Links.GET_UNDERWRITERS_LISTS; break;
    case 'agents': link = Links.GET_AGENT_LISTS; break;
    case 'list-lead-statuses': link = Links.GET_LEAD_STATUSES; break;
    default: link = ''; break;
  }

  return link;
}
