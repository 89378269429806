import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vc" }
const _hoisted_2 = { class: "fw-light text-gray-700 fs-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["form-label", _ctx.isRequired ? 'required' : ''])
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.subLabel), 1)
    ], 2),
    _createVNode(_component_el_form_item, { prop: _ctx.prop }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          type: "text",
          size: _ctx.size,
          class: "el-h-44",
          placeholder: "123-1234-1234567-1",
          modelValue: _ctx.emiratesID,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emiratesID) = $event)),
          onInput: _ctx.updateValue,
          onBlur: _ctx.autoMask,
          disabled: _ctx.disabled,
          maxlength: "18",
          ref: "inputRef"
        }, null, 8, ["size", "modelValue", "onInput", "onBlur", "disabled"])
      ]),
      _: 1
    }, 8, ["prop"])
  ]))
}