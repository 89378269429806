import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.email_content_data.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email_content_data.open) = $event)),
    onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal(false))),
    "append-to-body": "",
    title: "Email Details",
    width: "50%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "m-4",
        innerHTML: _ctx.email_content_data.content
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}