
import { defineComponent, ref, computed } from 'vue'
// import { documents } from '@/core/utils'
import { store } from '@/store';
import Documents from '@/core/services/Callable/Documents';
import {  constant, leadEmployeeCategoryId } from '@/store/stateless';

interface dataValue {
    is_loaded: Boolean
    categories: unknown
    lead_id: Number
    lead_employee_category_id?: number
}

export default defineComponent({
    props: {
        isView: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, { emit }) {
        const open = ref<Boolean>(false)
        const activeTab = ref<Number>(0)
        const activeValue = ref<Number>(0)
        const showUpload = ref(false)
        const { upload_documents } = constant

        const documents = computed(() => {
            const type = activeTab.value == 2 ? 1 : 0;
            return store.getters.getAllDocuments(type)
        })

        const leads = computed(() => {
            const data = store.getters.getLeads(true)
            let filtered = data.filter(x => !x.tab_disabled)
            if(!props.isView) {
                filtered = filtered.filter(y => y.insured_type_id !== 4)
            }
            const map = filtered.map(x => {
                return {
                    label: x.name,
                    value: x.lead_id,
                    lead_status_id:x.lead_status_id
                }
            })
            const exist = map.find(x => x.value === 0)
            if(!exist) map.unshift({ label: 'Select Lead', value: 0 })

            return map

        })
        
        const modal = (value: Boolean) => {
            open.value = value
            if(!value) {
                emit('update',upload_documents.lead_id)
                activeTab.value = 0
                activeValue.value = 0
                leadEmployeeCategoryId.value = 0
                upload_documents.lead_id = 0
                upload_documents.disabled = false
                upload_documents.group_name = ''
                console.log(upload_documents)

            }
            else {
                
                if(upload_documents.lead_id > 0)
                {
                    activeTab.value = 2
                    changeValue(upload_documents.lead_id)
                // console.log('upload_documents',upload_documents)
                // console.log('activeTab',activeTab.value)

                }
                else {
                    handleClick(0)
                }
                console.log(upload_documents)
            }
        }

        const changeValue = async(value: number) => {
            if(upload_documents.lead_id == 0)
                activeTab.value = 1
            activeValue.value = value
            await fetchDocument(value)
        }

        const switchTab = async(value) => {
            activeTab.value = value
            await fetchDocument(activeValue.value)
        }


        const handleClick = async (value: number = 0) => {
            value = value === 0 ? leads.value[1].value : 0
            activeValue.value = value
            activeTab.value = 1

            if(value > 0) await fetchDocument(value)
        }

        const employeeTable = ref()
        const fetchDocument = async(value: Number) => {
            activeValue.value = value

            const lead = leads.value.filter((x) => x.value == value);

            if(lead && lead[0])
                showUpload.value = [4,14,16].includes(lead[0].lead_status_id) ? false : true;

            const condition = <dataValue>{
                is_loaded: true,
                categories: ['standard'],
                lead_id: value
            }

            if(leadEmployeeCategoryId.value > 0) {
                condition.lead_employee_category_id = leadEmployeeCategoryId.value
                if(employeeTable.value)
                employeeTable.value.callDocumentTypes()
            }

            await Documents.getDocuments(condition)
        }

        const downloadZip = async () => {
            await switchTab(2)

            let policyDocArray = Array < any > ();
            let tempPolicyDocArray = Array < any > ();

            documents.value?.map((doc) => {
                // console.log(doc)
                // if (doc?.is_temp == 1 && doc?.document_type.toLowerCase() == 'verification') {
                //     tempPolicyDocArray.push(doc.document_id);
                // }
                // else if (doc?.is_temp == 0 && doc?.document_type.toLowerCase() == 'verification') {
                //     policyDocArray.push(doc.document_id);
                // }

                if (doc?.is_temp == 1 && (doc?.document_type == 'verification' || (doc.document_type_master &&  doc.document_type_master.document_type == 'verification'))) {
                    tempPolicyDocArray.push(doc.document_id);
                }
                else if (doc?.is_temp == 0 && (doc?.document_type == 'verification' || (doc.document_type_master &&  doc.document_type_master.document_type == 'verification'))) {
                    policyDocArray.push(doc.document_id);
                }
            })


            const payload = {
                policy: policyDocArray,
                temp: tempPolicyDocArray,
            }
            // console.log(payload);
            Documents.downloadZip(payload);
        };

        return {
            leadEmployeeCategoryId,
            employeeTable,
            activeValue,
            activeTab,
            documents,
            store,
            leads,
            open,
            modal,
            switchTab,
            handleClick,
            changeValue,
            fetchDocument,
            downloadZip,
            showUpload,
            upload_documents
        }
    },
})
