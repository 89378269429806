import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.allNotifications,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, i) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: i,
          width: notification.width
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", {
              class: "d-block fs-6 fw-bolder",
              innerHTML: notification.label
            }, null, 8, _hoisted_1)
          ]),
          default: _withCtx((scope) => [
            (notification.isLink)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  target: "_blank",
                  to: scope.row.url ? scope.row.url : '#',
                  class: "text-dark fw-bold"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "text-truncate text-dark fw-bold text-hover-primary cursor-pointer",
                      innerHTML: scope.row[notification.value]
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "text-truncate",
                  innerHTML: scope.row[notification.value]
                }, null, 8, _hoisted_3))
          ]),
          _: 2
        }, 1032, ["width"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["data"]))
}