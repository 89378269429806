import { GetterTree } from 'vuex';
import router from "@/router/clean";

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { docType } from '@/core/types/Document'

import { State } from './state';

export type Getters = {
    getAllDocuments(state: State): Array<docType> | any
}

export const getters: GetterTree<State, RootState> & Getters = {
    getAllDocuments: (state) => (is_temp:any = 2, is_view: boolean = false) => {
        if(state.documents.length > 0) {
            const data = state.documents.map(x => {
                let uploaded_by = ''
                if(x.created_by_customer) uploaded_by = x.created_by_customer.name
                if(x.created_by_skye_user) uploaded_by = x.created_by_skye_user.name
                let document_type = x.document_type ? x.document_type.type : 'Not selected'
                if(!x.is_temp) document_type = x.document_type ? x.document_type.type : 'Not Assigned'
                if(is_view) 
                    document_type = x.document_type ? x.document_type.type : 'Not Assigned'
                console.log('document_type',document_type)
                return {
                    document_id: x.id,
                    uuid: x.uuid,
                    link: x.file_extension.toLowerCase() !== 'pdf' ? x.file_path : 'media/svg/files/pdf.svg',
                    file_path: x.file_path,
                    is_temp: x.is_temp,
                    type: x.type ? x.type : 0,
                    document_type,
                    document_type_master: x.document_type,
                    file_name: x.original_filename,
                    quotes_ref_no: x.quote_ref_no ? x.quote_ref_no : '',
                    uploaded_by: `${x.created_at} <br />${uploaded_by}`,
                    file_extension: x.file_extension,
                    encrypted_file_path: x.encrypted_file_path,
                    categories: x.categories,
                    created_by_skye_user:x.created_by_skye_user
                }
            })

            if(is_temp === 0) {
                return data.filter(x => x.is_temp === 0)
            } else if(is_temp === 1) {
                return data.filter(x => x.is_temp === 1)
            } else {
                return data
            }

        }

        return []
    },

    getDocumentTypes: (state) => {
        if(state.document_types.length > 0) {
            const exist = state.document_types.find(x => x.value === 0)
            const data = state.document_types
            if(!exist) data.unshift({ value: 0, label: 'Select Document Type' })

            return data
        }
    },

    getDocumentActions: (state) => (roles, showDelete = false, hideDownload = false, showLog = false) => {
        return [
            {
                icon: 'icon-svg icon-delete',
                tip: 'Delete',
                value: 1,
                // show: [1, 4, 9, 14].some(x => roles.includes(x)) && showDelete
                show:  showDelete,
                isDelete: true
            },
            {
                icon: 'icon-svg icon-download',
                tip: 'Download',
                value: 2,
                show: !hideDownload
            },
            {
                icon: 'fa fa-eye text-info',
                tip: 'View',
                value: 3,
                show: true
            },
            {
                icon: 'icon-svg icon-document-log',
                tip: 'Document Log',
                value: 4,
                show: [1,6,9].some(x => roles.includes(x)) && showLog
            },
        ]
    },

    getDocumentLogs: (state) => {
        if(state.document_logs.length > 0) {
            return state.document_logs.map(x => {
                return {
                    action: x.action,
                    by: x.action_by ? x.action_by.name : '',
                    at: x.created_at
                }
            })
        }
    },

    getAmendValidation: (state) => {
        if(state.amend_documents.length > 0) {
            const docs = state.amend_documents.filter((x) => x.customer_id == null || x.filename == null)
            if(docs.length > 0)
                return true;

            return false;
        }

        return false;

    }

};

