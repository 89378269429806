
import { computed, defineComponent, ref, inject } from 'vue'
import { common, constant } from '@/store/stateless'
import { store } from '@/store'
import { timelines } from '@/core/utils'
import router from '@/router/clean'
import Invoice from '@/core/services/Callable/Invoice'
import Documents from '@/core/services/Callable/Documents'

const __default__ = defineComponent({
    props: {
        isTable: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        subTitle: {
            type: String,
            required: false,
            default: ''
        },
        isShowTitle: {
            type: Boolean,
            required: false,
            default: true
        },
        isShowSubTitle: {
            type: Boolean,
            required: false,
            default: false
        },
        items: {
            type: Array,
            required: true
        },
        values: {
            type: Object,
            required: true
        },
        hideData: {
            type: Boolean,
            required: false,
            default: false
        },
        interestedIn: {
            type: Boolean,
            required: false,
            default: true
        },
        interestedPolicy: {
            type: String,
            required: false,
            default: ''
        },
        lead_id: {
            type: Number,
            required: false,
            default: 0
        },
        lead: {
            type: Object,
            required: true

        },
        section: {
            type: String,
            required: false,
            default: ''
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false
        }

    },

    setup(_,context) {

        // console.log('values',_.lead)
        const interestedPolicyModal = computed(() => {
            return store.state.invoice.openInterestedPolicyModal;
        });

        const details:any = inject('details')

        const { view_task, merge_lead, view_declarations, upload_documents } = constant

        const clickEvent = (value: number) => {
            const { health_lead_map_id, lead_id } = _.values
            switch (value) {
                case 1: 
                    if(_.lead?.insured_type_id == 4)
                        openRoute('EditCorporateDetail', { lead_id }); 
                    else
                        openRoute('EditDetail', { lead_id }); 
                break;
                case 2: openRoute('GenerateQuote', { lead_id }); break; 
                case 3: 
                    openUploadDocument(lead_id)
                break;
                case 5: 
                    if(_.lead?.insured_type_id == 4)
                        openRoute('MakeGroupInvoice', { lead_id });
                    else
                        openRoute('MakeInvoice', { lead_id }); 
                    break;
                case 6: openViewDeclarations(lead_id); break;
                case 4: 
                    window.open(_.lead?.encrypted_map , '_blank');
                break;
                default: console.log(value)
            }
        }

        const openUploadDocument = (lead_id) => {
            upload_documents.lead_id = lead_id
            upload_documents.disabled = false
            context.emit('action');
        }

        const openViewDeclarations = (lead_id) => {
            view_declarations.open = true
            view_declarations.lead_id = lead_id
            view_declarations.is_fullscreen = true
            view_declarations.disabled = _?.lead?.show_make_invoice == false ? true : false
        }

        const openRoute = (value, params: any = {}, query: any = {}) => {
            let route: any = {}
            if (Object.keys(params).length > 0) {
                route = router.resolve({ name: value, params: params })
            } else {
                route = router.resolve({ name: value })
            }
            if (Object.keys(query).length > 0) route.query = query

            const corrected = router.resolve(route)
            window.location.href = corrected.href
            // router.push(corrected.fullPath).then(() => { router.go(0) })
        }

        const openViewTasks = (task) => {
            view_task.open = true
            view_task.customer_id = task.customer_id
            view_task.lead_id = task.lead_id
            view_task.lead_status_id = task.lead_status_id
        }

        const openMergeLead = (lead) => {
            merge_lead.open = true
            merge_lead.quick_lead_id = lead.lead_id
        }

        const openMerge = computed(() => {
            const leads = store.getters.getLeads(true)
            if (leads.length > 0) {
                if (leads.filter(x => [1, 3, 4, 6,5,7 ].includes(x.lead_status_id)).length > 0 && _.values.lead_status_id === 2) return true
                
                const renewal_leads = leads.filter(x => [5,7].includes(x.lead_status_id))
                // console.log('renewal_leads',renewal_leads)
                if(renewal_leads.length > 0 && _.values.lead_status_id === 1){
                    const sameInsuredType = renewal_leads.filter(x => x.insured_type_id == _.lead.insured_type_id);
                console.log('sameInsuredType',sameInsuredType)

                    if(sameInsuredType.length > 0){
                        return true;
                    }
                }


                // if(leads.filter(x => [4,6].includes(x.lead_status_id)).length > 0 && _.values.lead_status_id === 2) return true 
            }

            return false
        })

        const openInterested = () => {
            Invoice.getInterestedPolicy({
                lead_id: _.lead_id
            })
        }

        
            const sizeWrap = computed(() => {
                return details.aspect <= 1 ? 280 : 150
            })

            const sizeWrapBind = computed(() => {
                return `${sizeWrap.value}px`
            })

            const secondColumnWrap = computed(() => {
                return [1].includes(_.lead?.insured_type_id) ? `230px` : (_.lead?.insured_type_id == 4 ? '150px' : ( _.lead?.lead_status_id !== 4 ? '120px' : '150px'))
            })

            const thirdColumnWrap = computed(() => {
                return ![1].includes(_.lead?.insured_type_id) ? `230px` : '230px'
            })

            async function showDocument(lead, category) {
                // store.commit('SET_DOCUMENT_LISTS', docs)
                // setTimeout(() => {
                //     store.state.policy.policy_documents.open = true
                // }, 100);
                let data = <any>{
                    is_loaded: true,
                    categories: ['standard'],
                    lead_id: lead.lead_id,
                    policy_id: lead.latest_policy_purchase.policy_id,
                    lead_employee_category_id:  category.lead_employee_category_id
                }

                await Documents.getDocuments(data)
                policy_documents.value.open = true;

                // console.log({ lead, category })
            }

            const policy_documents = computed(() => {
                return store.state.policy.policy_documents
            })

            const fetchDocument = async () => {
                let data = <any>{
                    is_loaded: true,
                    categories: ['standard'],
                    lead_id: _.lead_id
                }
                if(_.lead && _.lead.latest_policy_purchase) 
                    data = { ...data, policy_id: _.lead.latest_policy_purchase.policy_id }
                    
                await Documents.getDocuments(data)
        
                policy_documents.value.open = true;
                }

        return { fetchDocument, clickEvent, openViewTasks, openRoute, openMergeLead, openMerge, common, timelines, store, interestedPolicyModal, openInterested, sizeWrapBind, sizeWrap, secondColumnWrap, thirdColumnWrap, showDocument, policy_documents  }
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "82eea2c2": (_ctx.secondColumnWrap),
  "676b81dc": (_ctx.thirdColumnWrap)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__