
import { defineComponent, ref, computed, onMounted } from 'vue'
import { documents } from '@/core/utils'
import { store } from '@/store';
import { notification } from '@/store/stateless';
import Documents from '@/core/services/Callable/Documents';
import Quote from '@/core/services/Callable/Quote';
import { subHeading, quoteButtons } from '@/core/utils/quotes'

export default defineComponent({
    setup() {
        const open = ref<Boolean>(false)
        const loading = ref<Boolean>(false)
        const activeTab = ref<Number>(0)
        const activeValue = ref<Number>(0)
        const quotes = computed(() => {
            return store.state.quote.manual_quotes
        });

        const modal = (value: Boolean) => {
            open.value = value
            if (value) {
                fetchQuotes();
            }
        }

        const customerDetails = computed(() => {
            return store.getters.getCustomer
        })

        const fetchQuotes = async () => {
            const payload = {
                customer_id: store.getters.getCustomer.customer_id
            }
            loading.value = true;
            await Quote.getViewQuotes(payload);
            loading.value = false;

        }

        const redirectToWhatsapp = (customerWhatsAppNo, docFiles) => {

            let message = docFiles + " -BuyAnyInsurance";

            let whatsAppURL = "https://wa.me/" + customerWhatsAppNo + "?text=" + message;
            window.open(whatsAppURL, '_blank');

        }

        const sendManualQuotes = async (quotation_id) => {
            const payload = {
                quotation_id : quotation_id
            }
            await Quote.sendManualQuotes(payload);

            notification.success('Quotation','Quotation send successfully !!');
            
        };

        function makeAction(object, value) {
            if(value === 'download') window.open(object.file_path)
            else if(value === 'send-email') sendManualQuotes(object.id)
            else redirectToWhatsapp(customerDetails.value.complete_number, object.short_url_path)
        }

        return {
            subHeading,
            quoteButtons,
            activeValue,
            activeTab,
            documents,
            store,
            quotes,
            open,
            modal,
            redirectToWhatsapp,
            customerDetails,
            sendManualQuotes,
            makeAction,
            loading
        }
    },
})
