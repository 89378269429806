import { GENERIC } from '@/core/types/Etc';


export type State = {
  dashboard_details:any;
  deleted_leads:any;
  filters:any;
  lead_sources: any;
  agent_sales_filter:any;
  agent_sales:any;
  agent_group_sales:any;
  policies_ac_filter:any;
  policies:any;
  policies_group:any;
  admin_report:any;
  approved_invoices_filter:any;
  approved_invoices:any;
  approved_group_invoices:any;
  closed_leads:any;
  converted_leads:any;
  lead_by_source:any;
  production_report:any;
  account_master:any;
  amount_spend:any;
  campaign_fiters:any;
  marketing_report:any;
  agent_lead_activity_report:any;
}


export const state: State = {
  dashboard_details: {
    data: [],
    total: 0
  },
  deleted_leads: {
    data: [],
    total: 0
  },
  filters: {
    years: [],
    months: [],
    lead_sources: [],
    utm_sources: [],
    utm_medium: [],
    utm_campaign: [],
    utm_content: [],
    lead_statuses: [],
  },
  lead_sources: [],
  agent_sales_filter:{
    page: 1,
    per_page: 50,
    lead_type: 1,
    start_date: "",
    end_date: "",
    payment_type: 0,
    agent: "",
    search: "",
    policy_status: "",
  },
  agent_sales:{
    data:[],
    total:0
  },
  agent_group_sales:{
    data:[],
    total:0
  },

  policies_ac_filter:{
    page: 1,
    per_page: 50,
    lead_type: 1,
    start_date: "",
    end_date: "",
    payment_type: 0,
    underwriter: "",
    search: "",
    policy_status: "",
  },

  policies:{
    data:[],
    total:0
  },
  policies_group:{
    data:[],
    total:0
  },

  admin_report:[],
  approved_invoices_filter:{
    page: 1,
    per_page: 50,
    lead_type: 1,
    start_date: "",
    end_date: "",
    invoice_type: "",
    search: "",
    payment_type: "",
  },

  approved_invoices:{
    data:[],
    total:0
  },
  approved_group_invoices:{
    data:[],
    total:0
  },

  closed_leads:{
    data:[],
    total:0
  },

  converted_leads:{
    data:[],
    total:0
  },

  lead_by_source: {
    data: [],
    total: 0
  },

  production_report:[],

  account_master: {
    data: [],
    total: 0
  },

  campaign_fiters:{},
  amount_spend:{
    total_spent:0,
    tracking: []
  },
  marketing_report:[],
  agent_lead_activity_report:[],

};
