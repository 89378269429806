import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "m-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "mb-10" }
const _hoisted_5 = { class: "col-sm-12" }
const _hoisted_6 = { class: "mb-10" }
const _hoisted_7 = { class: "d-flex justify-content-center mb-4 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _ctx.open,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.open) = $event)),
        "append-to-body": "",
        width: _ctx.common.isMobile() ? '100%' : '30%',
        title: "Update Customer",
        onClosed: _cache[5] || (_cache[5] = ($event: any) => (_ctx.close(_ctx.ruleFormRef))),
        onOpened: _ctx.setFocusTofn,
        draggable: "",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            model: _ctx.contact_info_payload,
            "status-icon": "",
            rules: _ctx.rules,
            id: "update_customer_contact_info",
            ref: "ruleFormRef",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submitCustomer(_ctx.ruleFormRef)), ["prevent"])),
            "scroll-to-error": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_vc_input, {
                        label: "Contact Information",
                        modelValue: _ctx.cd.other_contact_info,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cd.other_contact_info) = $event)),
                        size: "large",
                        disabled: true
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_vc_input, {
                        modelValue: _ctx.contact_info_payload.other_contact_info,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contact_info_payload.other_contact_info) = $event)),
                        prop: "other_contact_info",
                        label: "Update contact information",
                        placeholder: "Enter contact information",
                        size: "large",
                        isRequired: ""
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ])
              ]),
              _createVNode(_component_el_divider),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_vc_button, {
                  text: "Cancel",
                  className: "btn btn-light me-3",
                  onVcClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close(_ctx.ruleFormRef))),
                  "is-regular": ""
                }),
                _createVNode(_component_vc_button_save, { loading: _ctx.loading }, null, 8, ["loading"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["modelValue", "width", "onOpened"]))
    : _createCommentVNode("", true)
}