import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1d16b30"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  class: "rounded-3 badge badge-circle badge-secondary fs-7",
  style: {"color":"#000"}
}
const _hoisted_3 = {
  key: 1,
  class: "m-0 d-flex header-buttons-block align-items-center leads-and-customer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (!_ctx.topbar)
    ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        gutter: 10
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, index) => {
            return (_openBlock(), _createBlock(_component_el_col, {
              md: 3,
              sm: 24,
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["card-title card-title-container", { 'mb-2': _ctx.common.isMobile() }])
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass([`${_ctx.filter.lead_status_id == lead.value ? lead.click : lead.color}`, "btn btn-outline"]),
                    onClick: ($event: any) => (_ctx.$emit('leads', lead.value)),
                    style: {}
                  }, [
                    _createTextVNode(_toDisplayString(lead.text) + " ", 1),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(lead.counts), 1)
                  ], 10, _hoisted_1)
                ], 2)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: _normalizeClass([`${_ctx.filter.lead_status_id == lead.value ? lead.click : lead.color}`, "rounded-3 badge badge-circle badge-secondary fs-7 ps-5 pe-5 cursor-default mr-10"])
          }, _toDisplayString(lead.counts), 3))
        }), 128))
      ]))
}